.grid {
  display: grid;
  gap: 3.4rem 1.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  container-type: inline-size;
  grid-auto-rows: auto;
}

@media screen and (max-width: 1000px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 800px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 500px) {
  .grid {
    grid-template-columns: 1fr;
  }
}