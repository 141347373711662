.card {
  border: 0 !important;
  background-color: transparent !important;
  overflow: visible !important;
  height: 75%;
}

.carouselItem:hover {
  transition: all 200ms !important;
  transform: scale(1.1) !important;
}

.carouselItem, .carouselItemNoHover {
  border-radius: 40px !important;
  transform: scale(1.0) !important;
  border: 2px solid #f2f2f2 !important;
  z-index: 2 !important;
  height: 100% !important;
  box-sizing: content-box !important;/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#dfdcdc+18,dfdcdc+18,ffffff+19 */
  background: #dfdcdc !important; /* Old browsers */
  background: -moz-linear-gradient(top,  #dfdcdc 18%, #dfdcdc 18%, #ffffff 19%) !important; /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #dfdcdc 18%,#dfdcdc 18%,#ffffff 19%) !important; /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #dfdcdc 18%,#dfdcdc 18%,#ffffff 19%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dfdcdc', endColorstr='#ffffff',GradientType=0 ) !important; /* IE6-9 */
}

.description {
  font-size: 12px !important;
}

.time {
  font-size: 11px !important;
  display: inline-block !important;
  transform: translateY(-2px) !important;
}

.place {
  font-size: 12px !important;
  font-weight: bold !important;
  text-align: left;
}

.icon {
  margin-right: 5px !important;
}

.currentPricePos {
  position: absolute !important;
  bottom: 10px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: 100%;
}

.currentPriceHeader {
  font-size: 12px !important;
  color: #30ab4b !important;
  font-weight: bold !important;
}

.currentPrice {
  font-size: 16px !important;
  font-weight: bold !important;
}

.item {
height: 100% !important;
}