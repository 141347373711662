.header {
  color: white !important;
  font-weight: 800 !important;
  font-size: 2.4vw !important;
  width: 100%;
  text-align: center !important;
}

.list {
  font-size: 1vw !important;
  font-weight: 500 !important;
  padding-right: 10px;
}

.greyItem {
  background-color: #ededed;
  border-radius:    30px 0 0 30px;
  align-self: center;
  height: 14vw;
  max-height: 220px;
  display: flex;
}

.greyItem > div, .redItem > div {
  width: 100% !important;
}

.redItem {
  background-color: #e02804;
  color: white;
  border-radius:    0 30px 30px 0;
  align-self: center;
  height: 14vw;
  max-height: 220px;
  display: flex;
}

@media screen and (max-width: 600px) {
  .header {
    font-weight: 800 !important;
    font-size: 5vw !important;
  }

  .list {
    font-size: 3vw !important;
  }

  .greyItem {
    transform: translateX(5vw);
    height: 320px !important;
    padding-right: 4vw;
  }

  .redItem {
    transform: translateX(-5vw)  !important;
    border-radius: 0 30px 30px 0 !important;
    height: 320px !important;
    padding-left: 4vw;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .header {
    font-weight: 800 !important;
    font-size: 4vw !important;
    line-height: 3.5vw !important;
  }

  .greyItem {
    border-radius: 30px !important;
  }

  .redItem {
    border-radius: 30px !important;
  }

  .list {
    font-size: 1.2vw !important;
  }
}

@media screen and (min-width: 1300px) {
  .header {
    font-size: 40px !important;
  }

  .list {
    font-size: 14px !important;
  }
}