@media screen and (max-width: 600px) {
  .main > li {
    font-size: 3vw !important;
  }
}

.main > li {
  padding-top: 2rem !important;
}

.main > li:first-child {
  padding-top: 0rem !important;
}

.list * {
  list-style: none !important;
}

.list ol li ul li {
  list-style-type: disc !important;
}

.list li ol {
  counter-reset: section !important;
  font-weight: normal !important;
  position: relative !important;
}
.list li dl {
  font-weight: normal !important;
}

.list > li > ol > li::before {
  counter-increment: section !important;
  list-style-position: outside !important;
  content: counters(section, ".") ". " !important;
  position: absolute !important;
  left: 0 !important;
}

.list > li > ol > li > ol > li::before {
  counter-increment: section !important;
  list-style-position: outside !important;
  content: counters(section, ".") ". " !important;
  position: absolute !important;
  left: 0 !important;
}

.list[type="a"] {
  list-style: lower-alpha !important;
}

.list[type="a"] li::before {
  content: normal !important;
}
