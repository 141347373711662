.header {
  color: #1c1c1e !important;
  font-weight: 900 !important;
}

.klokan {
  width: 380px;
  margin-top: -100px;
  position: relative;
  left: -125px;
}

@media screen and (max-width: 600px) {
  .klokan {
    width: 45vw;
    position: relative;
    left: -5vw;
    margin-top: inherit;
  }
}

