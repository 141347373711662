.image {
  max-width: 95% !important;
  margin: auto !important;
  max-height: 95% !important;
  object-fit: contain !important;
}

.name {
  color: #1c1c1e !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  padding-top: 140px !important;
  font-size: 20px !important;
  height: 100% !important;
}

.offerCountText {
  color: #1c1c1e !important;
  font-weight: 700 !important;
  padding-top: 20px !important;
  font-size: 12px !important;
}

.offerCount {
  color: #e02804 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
}

.bottom {
  position: absolute !important;
  bottom: 0px !important;
  margin-left: 50% !important;
  transform: translateX(-50%) !important;
  width: 100% !important;
}