.header {
  color: #1c1c1e !important;
  font-weight: 900 !important;
}

.image {
  transform: rotate(90deg) !important;
  position: relative !important;
  top: 25px !important;
}

.klokan {
  width: 250px;
  margin-top: -60px;
}

@media screen and (max-width: 600px) {
  .klokan {
    width: 45vw;
    position: relative;
    margin-top: -5vw;
  }
}


@media screen and (min-width: 600px) and (max-width: 800px) {
  .klokan {
    width: 30vw;
    max-width: 350px;
    position: relative;
    margin-top: -5vw;
    left: 35%;
  }
}
@media screen and (min-width: 800px) and (max-width: 900px) {
  .klokan {
    width: 30vw;
    max-width: 350px;
    position: relative;
    margin-top: -8vw;
    left: 35%;
  }
}


