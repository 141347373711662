.button {
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #818084 !important;
  text-decoration: none !important;
  cursor: pointer !important;
  display: inline-block !important;
  user-select: none !important;
}

.blackBar .button, .redBar .button {
  color: white !important;
  padding: 8px 20px !important;
}

.redBar .button[class~=active] {
  color: black !important;
  font-weight: bold !important;
}

.buttonPosition {
  position: relative !important;
  top: 14px  !important;
  font-size: 13px !important;
}

.blackBar {
  background-color: #1c1c1e !important;
  height: 35px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  border-bottom-left-radius: 100px !important;
  border-bottom-right-radius: 100px !important;
  position: relative !important;
  z-index: 2 !important;
}

.redBar {
  background-color: #e02804 !important;
  z-index: 1 !important;
  height: 35px !important;
  padding-left: 50px !important;
  padding-right: 45px !important;
  border-bottom-left-radius: 100px !important;
  border-bottom-right-radius: 100px !important;
}

.button[class~=active] {
  color: #e02804 !important;
  font-weight: bold !important;
}

.chevron {
  margin-left: 5px !important;
  transform: translateY(8px) !important;
}

.klokanImage {
  position: absolute !important;
  left: -130px !important;
}