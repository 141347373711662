.list {
  padding: 0 !important;
  padding-top: 20px !important;
  padding-left: 35px !important;
}

@media screen and (max-width: 600px) {
  .text p {
    padding-right: 40px !important;
  }
}

.text p {
  font-size: 14px !important;
  text-align: justify !important;
}

.muted {
  color: #818084 !important;
  font-size: 12px !important;
}

.list li::marker {
  color: #e02804 !important;
  content: "0" counter(list-item) ". " !important;
  font-weight: 900 !important;
  font-size: 15px !important;
}

.faqlink {
  padding-top: 30px !important;
}