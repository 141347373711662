.body {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #b9b9b9 !important;
  padding-left: 60px !important;
}

.headerText {
  font-size: 13px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  color: #ffffff !important;
}

.header {
  padding-bottom: 10px !important;
  align-items: baseline;
}

.buttonLikeHeader {
  font-size: 12px !important;
  font-weight: bold !important;
  text-transform: none !important;
  border-radius: 20px !important;
  padding: 5px 20px !important;
  color: white !important;
  background-color: #30ab4b !important;
  width: fit-content !important;
}

.bullet {
  margin: 0 20px !important;
  transform: translateY(5px) !important;
}