
.list {
  position: relative !important;
}

.list li {
  counter-increment: counter !important;
  list-style: none !important;
  padding-bottom: 45px !important;
  padding-left: 15px !important;
  padding-top: 22px !important;
  display: flex !important;
  align-items: center !important;
}

@media screen and (max-width: 600px) {
  .list li {
    padding-bottom: 15px !important;
    align-items: flex-start !important;
    padding-left: 30px !important;
    padding-right: 15px !important;
    font-size: 0.8em !important;
  }
}

.list li:last-child {
  padding-bottom: 0 !important;
}

.list li::before {
  content: "0" counter(counter) "." !important;
  padding-left: 30px !important;
  font-weight: 800 !important;
  font-size: 30px !important;
  color: #e02804 !important;
  position: absolute !important;
  left: -30px !important;
}

.button {
  font-weight: normal !important;
  padding: 0 !important;
  padding-left: 5px !important;
}

.checkboxLabel {
  font-size: 12px !important;
  color: white !important;
}

.form label {
  font-weight: 400 !important;
}