.title {
  color: #818084 !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
  font-size: 12px !important;
}

.name {
  font-size: 20px !important;
  font-weight: 900 !important;
}