.footer {
  text-align: right !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background-color: #1c1c1e;
  width: 100%;
}

.link, .mutedLink {
  color: #fff !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  display: block !important;
}

.mutedLink {
  color: #acabaf !important;
}

.lowerGrid {
  padding-top: 50px !important;
  line-height: 2 !important;
  text-align: left !important;
}

.middleGrid {
  padding-top: 50px !important;
  line-height: 2 !important;
  text-align: left !important;
  display: flex !important;
  justify-content: center !important;
}

.higherGrid {
  padding-top: 20px !important;
  line-height: 2 !important;
}

.higherGrid [class~=MuiTypography-body1] {
  color: #fff !important;
  font-size: 12px !important;
  line-height: 2 !important;
}

.company {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #acabaf !important;
}

.company strong {
  color: #e02804 !important;
  font-weight: bold !important;
}

