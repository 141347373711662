.count {
  padding-top: 50px !important;
  color: #00ac54 !important;
  font-size: 36px !important;
  font-weight: bold !important;
  display: flex !important;
  text-align: center !important;
  line-height: normal !important;
  flex-direction: column !important;
}

.count strong {
  padding-top:40px !important;
  padding-bottom: 30px !important;
  color: #333333 !important;
  font-weight: 900 !important;
  font-size: 100px !important;
  line-height: 0.15 !important;
}

.count strong small {
  font-weight: 600 !important;
  font-size: 50px !important;
}