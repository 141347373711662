.monitorImage {
  width: 30vw;
  max-width: 470px;
  position: absolute;
  top: 5%;
  left: 0;
  z-index: -1;
}

.bannerImage {
  width: clamp(100px, 28.55vw, 455px);
  z-index: 3;
  transform: translate(8px, min(1.75vw, 23px));
  aspect-ratio: 328/184;
}

.klokanImage {
  position: absolute;
  top: 10%;
  width: 10.4vw;
  max-width: 150px;
  right: -20px;
  z-index: 2
}

@media screen and (min-width: 900px) and  (max-width: 1600px) {
.klokanImage {
  right: -41%;
}
}

@media screen and (min-width: 600px) and  (max-width: 900px) {
  .klokanImage {
    display: none;
  }

  .bannerImage {
    width: 100% !important;
    z-index: 2 !important;
    position: static;
    transform: unset;
  }
}

@media screen and (max-width: 600px) {
  .klokanImage {
    left: -2vw;
    position: absolute;
    bottom: -2.55vw;
    top: inherit;
    width: 25vw;
  }

  .bannerImage {
    width: 100% !important;
    z-index: 2 !important;
    position: static;
    transform: unset;
  }
}

.video {
  position: absolute;
  top: 17px;
  left: 10px;
}