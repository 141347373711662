$swiper-navigation-size: 18px;

html, body {
  -webkit-text-size-adjust: none; /* disable safari text adjustments */
  width: 100%;
  overflow-x: hidden; /* iOS fix for swiper */
  position: relative;
}

body {
  overflow: hidden;
  position: relative;
}

/* fixing incorrect popper and setting footer to the bottom of the page */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  text-decoration: none;
}

.MuiLink-root {
  text-decoration: unset;
}

.MuiTypography-h1 {
  color: #1c1c1e;
  font-weight: 900;
  font-size: 40px;
}
.MuiTypography-h1 span, .MuiTypography-h2 span, .MuiTypography-h1 strong, .MuiTypography-h2 strong {
  color: #e02804;
  font-weight: 900;
}
.MuiTypography-h2 {
  color: #1c1c1e;
  font-weight: 900;
  font-size: 30px;
}
.MuiTypography-body1 {
  color: #1c1c1e;
  font-size: 20px;
}

@media screen and (max-width: 600px) {
.responsiveTable tbody td {
  font-size: 3vw;
}
.responsiveTable tbody tr {
    border: 0px;
    border-bottom: 1px dashed rgb(192, 192, 192);
    padding: 1rem 0;
}

  ul, ol {
    padding-left: 6vw;
  }

  .MuiPagination-ul {
    padding-left: 0;
  }

  .MuiList-root {
    padding-left: inherit;
  }
  .MuiTypography-h1 {
    color: #1c1c1e;
    font-weight: 900;
    font-size: 4vw;
  }
  .MuiTypography-h1 span, .MuiTypography-h2 span, .MuiTypography-h1 strong, .MuiTypography-h2 strong {
    color: #e02804;
    font-weight: 900;
  }
  .MuiTypography-h2 {
    color: #1c1c1e;
    font-weight: 900;
    font-size: 4vw;
  }
  .MuiTypography-h1 + .MuiTypography-h2 {
    font-size: 3.6vw;
    padding-top: 1vw;
  }
  .MuiTypography-h4 {
    color: #1c1c1e;
    font-weight: 900;
    font-size: 4vw;
  }
  .MuiTypography-h5 {
    font-size: 4vw;
    font-weight: 500;
  }
  .MuiTypography-h6 {
    color: #1c1c1e;
    font-weight: 900;
    font-size: 3vw;
    text-align: center;
  }
  .MuiTypography-body1 {
    color: #1c1c1e;
    font-size: 3vw;
  }
}

.MuiButton-sizeMedium {
  font-size: 12px;
  font-weight: bold;
  text-transform: none;
  border-radius: 20px;
  padding: 5px 20px;
}

.MuiButton-sizeLarge {
  font-size: 20px;
  font-weight: bold;
  text-transform: none;
  border-radius: 20px;
  padding: 5px 20px;
}

.MuiLink-root {
  font-weight: bold;
  text-decoration: none;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-prev, .swiper-button-next {
  color: #1c1c1e ;
  width: calc($swiper-navigation-size / 44 * 27);
  margin-top: calc(0px - ($swiper-navigation-size / 2));
  height: 100%;
  display: flex;
  top: 0;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: $swiper-navigation-size;
}

.swiper-slide {
  height: auto;
  align-self: stretch ;
}

.MuiFormLabel-colorLight, .MuiInputBase-colorLight {
  color: white;
}

.MuiInputBase-colorLight button {
  color: white
}

.MuiInputBase-colorLight::before {
  border-color: white;
}

.MuiInputBase-colorLight + p.MuiFormHelperText-root {
  color: white;
}

.MuiCheckbox-colorLight {
  color: white;
}

.MuiCheckbox-colorLight + .MuiFormControlLabel-label {
  color: white;
}

@media (min-width: 1200px) {
  .MuiContainer-root {
      max-width: 1400px;
  }
}

.MuiInputLabel-standard {
  font-weight: 900;
}

.MuiTableCell-head {
  font-size: 12px;
  font-weight: bold;
}

.MuiTableCell-body {
  font-size: 13px;
  color: #1c1c1e;
}

a[role='menuitem'][aria-current='page'] {
  color: #e02804;
  font-weight: bold;
;
}