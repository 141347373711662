.header {
  color: #1c1c1e !important;
  font-weight: 900 !important;
}

.header strong {
  font-weight: 900 !important;
}

.klokan {
  width: 330px;
  margin-top: -150px;
  top: 50px;
  position: relative;
  left: 100px;
}

@media screen and (max-width: 600px) {
  .klokan {
    width: 45vw;
    position: relative;
    left: -5.5vw;
    top: -40px;
    margin-top: inherit;
  }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
  .klokan {
    width: 30vw;
    position: relative;
    left: -1.5vw;
    margin-top: 8%;
    top: -7%;
  }
}

@media screen and (min-width: 750px) and (max-width: 800px) {
  .klokan {
    width: 30vw;
    position: relative;
    left: -1.5vw;
    margin-top: -5%;
    top: -7%;
  }
}

@media screen and (min-width: 700px) and (max-width: 750px) {
  .klokan {
    width: 30vw;
    position: relative;
    left: -1.5vw;
    margin-top: 5%;
    top: -7%;
  }
}

@media screen and (min-width: 800px) and (max-width: 900px) {
  .klokan {
    width: 30vw;
    position: relative;
    left: -1.5vw;
    margin-top: -10%;
    top: -7%;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .klokan {
    width: 30vw;
    position: relative;
    margin-top: -33%;
    top: -7%;
  }
}




