.text {
  font-size: 20px !important;
  color: '#1c1c1e' !important;
}

.title {
  margin-bottom: 60px !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  color: '#1c1c1e' !important;
}

@media screen and (max-width: 600px) {
  .text {
    font-size: 3vw !important;
    color: '#1c1c1e' !important;
  }
  .title {
    font-size: 4vw !important;
    font-weight: 600 !important;
    color: '#1c1c1e' !important;
  }
}